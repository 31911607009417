interface FooterNavItemProps {
  href: string
  label: string
  newTab?: boolean
}

const FooterNavItem = (props: FooterNavItemProps) => {
  const { href, label, newTab = false } = props

  return (
    <div className="inline-flex items-center justify-start">
      <a
        href={href}
        className={`text-center font-sans text-sm leading-tight`}
        target={newTab ? "_blank" : undefined}
        rel={newTab ? "noreferrer noopener" : undefined}
      >
        {label}
      </a>
    </div>
  )
}

export default FooterNavItem
