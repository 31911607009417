import AmariTherapist1 from "@/assets/img/amari_therapist_1.png"

const FooterCta = () => {
  return (
    <div className="relative inline-flex origin-top-left flex-col items-start justify-center overflow-hidden rounded-[50px] bg-amari-gradient-1 bg-cover bg-no-repeat pt-10 lg:h-[390px] lg:flex-row lg:overflow-visible lg:px-[120px] lg:py-20">
      <div className="p-6 lg:p-0">
        <div className="lg:w-1/2">
          <div className="text-xl font-bold leading-9 text-slate-800 lg:text-3xl lg:text-white">
            Need to chat?
          </div>
          <div className="my-8 text-xl font-normal leading-9 text-slate-800 lg:text-3xl lg:text-white">
            Send us a message and we'll get back to you as soon as possible.
          </div>
        </div>
        <div className="inline-flex h-[60px] items-center justify-center gap-1.5 rounded-[40px] bg-white px-10 py-4 shadow-lg hover:shadow-xl">
          <div className="flex items-center justify-center gap-1.5 px-[7px] ">
            <a href="/contact-us" className="text-xl font-bold leading-7 text-slate-800">
              Get in touch
            </a>
          </div>
        </div>
      </div>

      <img
        className="bottom-0 mt-8 h-auto w-full px-6 lg:absolute lg:right-0 lg:mr-10 lg:mt-0 lg:w-[424px] lg:px-0"
        src={AmariTherapist1.src}
      />
    </div>
  )
}

export default FooterCta
