import Facebook from "@/assets/img/facebook.svg"
import LinkedIn from "@/assets/img/linkedin.svg"
import TikTok from "@/assets/img/tiktok.svg"
import Twitter from "@/assets/img/twitter.svg"

const FooterSocial = () => {
  return (
    <div className="inline-flex h-6 items-start justify-start gap-4">
      <div className="relative h-6 w-6">
        <a href="https://www.facebook.com/thepocketcouch" target="_blank" rel="noreferrer">
          <img src={Facebook.src} alt="Facebook icon" />
        </a>
      </div>
      <div className="relative h-6 w-6">
        <a href="https://twitter.com/amariHQ/" target="_blank" rel="noreferrer">
          <img src={Twitter.src} alt="Twitter icon" />
        </a>
      </div>
      <div className="relative h-6 w-6">
        <a href="https://www.linkedin.com/company/amarihealthhq/" target="_blank" rel="noreferrer">
          <img src={LinkedIn.src} alt="LinkedIn icon" />
        </a>
      </div>
      <div className="relative h-6 w-6">
        <a href="https://www.tiktok.com/@amari.hq" target="_blank" rel="noreferrer">
          <img src={TikTok.src} alt="TikTok icon" />
        </a>
      </div>
    </div>
  )
}

export default FooterSocial
