import ShieldCheck from "@/assets/img/shield_check.svg"

const FooterCompliance = () => {
  return (
    <div className="inline-flex h-20 items-center justify-center gap-8 rounded-[40px] border border-violet-100 bg-white py-3 pl-8 pr-3 shadow">
      <div className="flex items-center justify-center gap-3">
        <div className="text-center text-sm font-normal leading-9 text-slate-800 sm:text-[28px]">
          HPCSA & HIPPA
        </div>
      </div>
      <div className="flex items-center justify-center gap-3 rounded-[40px] border border-cyan-500 bg-cyan-100 px-6 py-4">
        <div className="text-center text-base font-normal leading-normal text-slate-800">
          Compliant
        </div>
        <div className="relative h-6 w-6">
          <img src={ShieldCheck.src} alt="Shield check icon" />
        </div>
      </div>
    </div>
  )
}
export default FooterCompliance
