import FooterCompliance from "@/components/Global/FooterCompliance"
import FooterCta from "@/components/Global/FooterCta"
import FooterNav from "@/components/Global/FooterNav"
import { cn } from "@/lib/utils/styling"

interface FooterProps {
  whiteBg?: boolean
}

const Footer = (props: FooterProps) => {
  return (
    <div
      className={cn("grid justify-center", {
        "bg-gray-100": !props.whiteBg,
        "bg-white": props.whiteBg,
      })}
    >
      <footer className="m-3 grid justify-center pt-10 sm:pt-32">
        <div className="my-4 text-center sm:my-12">
          <FooterCompliance />
        </div>

        <div className="my-4 sm:mb-12">
          <FooterCta />
        </div>

        <div className="sm:mb-20">
          {" "}
          <FooterNav />
        </div>
      </footer>
      <div className="mb-6 inline-flex flex-wrap items-center justify-center gap-y-4  border-t border-slate-300 py-6 text-center text-base font-normal leading-snug text-gray-500 sm:border-slate-800">
        <span className="border-r border-gray-500 px-1">Copyright © 2024 Amari </span>
        <span className="border-gray-500 px-1 sm:border-r">All Rights Reserved </span>
        <span className="border-r border-gray-500 px-1">
          <a target="_blank" className="underline" href="/terms_of_service.pdf">
            Terms & Conditions
          </a>
        </span>
        <span className="pl-2">
          <a target="_blank" className="underline" href="/privacy_policy.pdf">
            Privacy Policy
          </a>
        </span>
      </div>
    </div>
  )
}

export default Footer
