import FooterNavItem from "@/components/Global/FooterNavItem"

const FooterNavList = () => {
  return (
    <div className="my-4 grid grid-cols-3 justify-between justify-items-center gap-6 sm:my-0 lg:flex lg:justify-start lg:gap-10">
      <FooterNavItem href="/free-screening" label="Free Screening" />
      <FooterNavItem href="/about" label="About" />
      <FooterNavItem href="/employers" label="Employers" />
      <FooterNavItem href="/therapists" label="Therapists" />
      <FooterNavItem href="/contact-us" label="Contact Us" />
    </div>
  )
}

export default FooterNavList
