import FooterLogo from "@/components/Global/FooterLogo"
import FooterNavList from "@/components/Global/FooterNavList"
import FooterSocial from "@/components/Global/FooterSocial"

const FooterNav = () => {
  return (
    <div className="flex flex-col items-center justify-around sm:flex-row">
      <div className="my-9 sm:my-0">
        <FooterLogo />
      </div>

      <div className="mb-8 sm:mb-0">
        <FooterNavList />
      </div>

      <div>
        <FooterSocial />
      </div>
    </div>
  )
}

export default FooterNav
