import AmariLogo from "@/assets/img/amari_logo.svg"

const FooterLogo = () => {
  return (
    <div className="w-[121px]">
      <img src={AmariLogo.src} alt="Amari logo" />
    </div>
  )
}

export default FooterLogo
